/**
 * sanitize past hack data
 * @param {Object} response
 * @returns {Object}
 */
export const transformPastHacksData = response => {
  var numRows = response.values ? response.values.length : 0;
  console.log(`${numRows} rows retrieved.`);
  const pastHackData = response.values
    ? response.values.map(item => ({
        rowId: item[0],
        hackTitle: item[1],
        teamMembers: item[2],
        hackDescription: item[3],
        hackType: item[4],
        productType: item[5],
        awardStatus: item[6],
        presentationLink: item[7],
        date: item[8],
      }))
    : [];

  return pastHackData;
};
