import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Card from '../components/hackCard';
import { getPastHacks } from '../api/PastHacks';
import { search } from '../util';
import SearchBar from '../components/common/SearchBar';
import SkeletonONECard from '../components/SkeletonCardLoader';

const PreviousHackdaysPage = () => {
  const [error, setError] = useState(false);
  const [filterPastHackdayData, setFilterPastHackdayData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [pastHackdayData, setPastHackdayData] = useState([]);
  const [query, setQuery] = useState('');
  const [searchType, setSearchType] = useState('title');

  useEffect(() => {
    async function setPastHackdayHacks() {
      try {
        setIsLoading(true);
        const response = await getPastHacks();
        const pastHackData = response.data;
        setPastHackdayData(pastHackData);
        setIsLoading(false);
      } catch (error) {
        console.warn(error);
        setError(true);
      }
    }
    setPastHackdayHacks();
  }, []);
  useEffect(() => {
    if (!query.length) {
      setNoResults(false);
    }
  }, [query.length]);

  const renderNoSearchResults = () => {
    if (noResults && query.length) {
      return <div className="no-results-banner">NO RESULTS</div>;
    }
  };

  const renderCardData = () => {
    const data = query.length <= 1 && noResults === false ? pastHackdayData : filterPastHackdayData;
    return data.map((item, i) => (
      <li key={uuidv4()} style={{ display: 'flex', width: '525px', marginBottom: '15px' }}>
        <Card currentHackday={false} item={item} />
      </li>
    ));
  };

  return (
    <>
      <div
        style={{
          alignItems: 'center',
          margin: '15px 0 ',
        }}
      >
        <SearchBar
          query={query}
          search={event =>
            search(
              event,
              pastHackdayData,
              searchType,
              setFilterPastHackdayData,
              setNoResults,
              setQuery
            )
          }
          setSearchType={event => setSearchType(event.target.value)}
          hackday="previous"
        />
      </div>
      {renderNoSearchResults()}
      {error && (
        <div>
          Looks like there has been an error, please contact Mark Delcambre or Nakita Strangeways!
        </div>
      )}
      {isLoading && <SkeletonONECard />}
      {!isLoading && (
        <ul
          style={{
            display: 'flex',
            justifyContent: 'space-evenly',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {renderCardData()}
        </ul>
      )}
    </>
  );
};

export default PreviousHackdaysPage;
