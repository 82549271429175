import { transformPastHacksData } from './utilities/helpers';
import api from '../index';

const spreadsheetId = '1zRKt1XSBN8ZhZAtqtPuyggiYyEIQElcvVzoJOBcQA2o';

/**
 * reads all rows for past hack google sheet
 * @return {Promise}
 */
export const getPastHacks = () => {
  const range = 'PastHackSheet!A2:I';
  const url = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${range}`;
  return api({
    method: 'get',
    transformResponse: [data => transformPastHacksData(JSON.parse(data))],
    url: url,
  });
};
